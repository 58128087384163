import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, LinearProgress, makeStyles, Typography } from '@material-ui/core';
import { Field } from 'formik';
import { uploadFile } from '../../lib/utils';
import defaultAvatar from '../../images/MLNP_Heart_Symbol.png';

const useStyle = makeStyles(() => ({
  inputFile: {
    display: 'none',
  },
  imgWrap: {
    width: '150px',
    height: '150px',
    position: 'relative',
    borderRadius: '50%',
    overflow: 'hidden',
  },
  img: {
    width: '100%',
    minHeight: '100%',
    position: 'absolute',
  }
}));

export const UploadField = ({ item, value }) => {
  const [uploadedFile, setUploadedFile] = useState('');
  const [progress, setProgress] = useState(null);
  const { inputFile, img, imgWrap } = useStyle();

  useEffect(() => {
    if (value) return setUploadedFile(`${process.env.GATSBY_CLOUDFRONT_DOMAIN}${value.key}`);
    return setUploadedFile(defaultAvatar);
  }, [])
  
  const handleUpload = (file, setFieldValue) => {
    setProgress(0);
    uploadFile(file, setProgress)
    .then(data => {
      setUploadedFile(`${process.env.GATSBY_CLOUDFRONT_DOMAIN}${data.key}`);
      setFieldValue(item.key, data);
    })
    .catch(err => console.log(err));
  };

  return (
    <Field name={item.key}>
      {({ form: { setFieldValue }}) => (
        <Grid container justify='center'>
          <Grid item xs={12} md={4}>
            <label htmlFor={item.key}>
              <Button
                color="primary"
                variant="contained"
                size="medium"
                component="span"
              >
                Choose File
                <input
                  accept="image/*"
                  className={inputFile}
                  id={item.key}
                  type="file"
                  onChange={(event) => handleUpload(event.target.files[0], setFieldValue)}
                />
              </Button>
            </label>
          </Grid>
          <Grid item xs={12} md={8}>
            {progress !== null &&
              <Box display="flex" alignItems="center">
                <Box width="100%" mr={1}>
                  <LinearProgress variant="determinate" value={progress} />
                </Box>
                <Typography variant="body2" color="textSecondary">{`${progress}%`}</Typography>
              </Box>
            }
            <Box className={imgWrap}>
              {uploadedFile && <img src={uploadedFile} className={img} alt='Profile picture' />}
            </Box>
          </Grid>
        </Grid>
      )}
    </Field>
  )
}