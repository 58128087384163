import React from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import { Field, useField } from 'formik';

export const InputField = ({ item }) => {
  const [field, meta] = useField(item.key);
  const errorText = meta.error && meta.touched ? meta.error : '';
  return (
    <Field
      {...field}
      type={item.type || 'input'}
      name={item.key}
      label={item.label}
      variant='outlined'
      size='small'
      fullWidth
      helperText={errorText || item.helperText}
      error={!!errorText}
      InputProps={{
        endAdornment: item.end && (<InputAdornment>{item.end}</InputAdornment>),
        startAdornment: item.start && (<InputAdornment>{item.start}</InputAdornment>)
      }}
      as={TextField}
    />
  )
}