import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Field } from 'formik';

export const CheckboxField = ({ item, value }) => {
  return (
    <Field
      name={item.key}
      control={<Checkbox color="primary" checked={value} />}
      label={item.label}
      as={FormControlLabel}
    />
  );
}