import React from 'react';
import { Box, Button, Grid, TextField } from '@material-ui/core';
import { Field, useField } from 'formik';

const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"

export const AutogenPasswordField = ({ item }) => {
  const [field, meta] = useField(item.key);
  const errorText = meta.error && meta.touched ? meta.error : '';

  const handleGeneratePassword = () => {
    let random = '';
    for (let i = 0; i < 10; i++) {
      random += charset.charAt(Math.floor(Math.random() * charset.length));
      if (i === 3) random += '1';
      if (i === 5) random += 'A';
      if (i === 8) random += 'a';
    };
    return random;
  };

  return (
    <Field name={item.key}>
      {({ form: { values, setFieldValue } }) => (
        <Box>
          <Grid container justify="flex-end">
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={() => setFieldValue(item.key, handleGeneratePassword())}
            >Generate Password</Button>
          </Grid>
          <TextField
            label={item.label}
            value={values[item.key]}
            fullWidth
            size="small"
            variant="outlined"
            helperText={errorText || item.helperText}
            error={!!errorText}
            onChange={(event) => setFieldValue(item.key, event.target.value)}
          />
        </Box>
      )}
    </Field>
  )
}