import React from 'react';
import { Field, useField } from 'formik';
import { TextField } from '@material-ui/core';

export const AreaField = ({ item }) => {
  const [field, meta] = useField(item.key);
  const errorText = meta.error && meta.touched ? meta.error : '';
  return (
    <Field
      {...field}
      type='input'
      name={item.key}
      label={item.label}
      variant='outlined'
      size='small'
      fullWidth
      multiline
      rows={7}
      helperText={errorText || item.helperText}
      error={!!errorText}
      as={TextField}
    />
  )
}