import React from 'react';
import { Auth, Storage } from 'aws-amplify';
import { RenameFile } from './rename';
import { AreaField } from '../components/input/area-field';
import { AutogenPasswordField } from '../components/input/autogen-password-field';
import { CheckboxField } from '../components/input/checkbox';
import { InputField } from "../components/input/input-field";
import { SelectField } from '../components/input/select-field';
import { UploadField } from '../components/input/upload-field';

export const convertSecondsTohms = (seconds) => {
  if (isNaN(seconds)) {
    return '00:00:00';
  }
  seconds = Number(seconds);
  let h = Math.floor(seconds / 3600);
  let m = Math.floor(seconds % 3600 / 60);
  let s = Math.floor(seconds % 60);

  return addZeroIfLessThan10(h) + ':' + addZeroIfLessThan10(m) + ':' + addZeroIfLessThan10(s);
}

export const addZeroIfLessThan10 = (n) => {
  return (n < 10 ? '0' : '') + n;
}

export const getUrlVideoByHeight = (width, path) => {
  path = path.key;
  let filename = path.split('/').pop();
  let filenameWithoutExtention = filename.split('.')[0];
  console.log(filenameWithoutExtention);
  let folderpath = path.replace(filename, '');
  let result = 'videos/' + folderpath;
  switch (width) {
    case 360:
      result += filenameWithoutExtention + '/' + filenameWithoutExtention + '.m3u8';
      break;
    case 720:
      result += filenameWithoutExtention + '/' + filenameWithoutExtention + '.m3u8';
      break;
    default:
      result += filenameWithoutExtention + '/' + filenameWithoutExtention + '.m3u8';

  }

  return result;
}

export const pluralize = (count, string, plural) => {
  if (count === 0 || count === 1) {
    return `${count} ${string}`;
  } else {
    return `${count} ${plural}`
  }
}

export const truncate = (str, n) => {
  return (str.length > n) ? str.substr(0, n - 1) + '...' : str;
}

export const getImage = s3object => {
  return `${process.env.GATSBY_CLOUDFRONT_DOMAIN}${s3object?.key}`
}

export const getGender = value => {
  const defaultPronounsList = ["She/Her", "He/Him", "They/Them", "Ze/Hir", "Ze/Zir"];
  return defaultPronounsList[value - 1];
}

export const getSubscriptionPackage = value => {
  const subPackage = value === 1 ? 'Quickie' : value === 2 ? 'Monthly' : value === 3 ? 'Binge' : 'No membership';
  return subPackage;
}

export const getInputType = (item, value, helperText, setFieldValue) => {
  if (item.type === 'checkbox') return <CheckboxField {...{ item }} />;
  if (item.type === 'text-area') return <AreaField {...{ item }} />;
  if (item.type === 'select') return <SelectField {...{ item, value, setFieldValue }} />;
  if (item.type === 'file-upload') return <UploadField {...{ item, value }} />;
  if (item.type === 'auto-gen-password') return <AutogenPasswordField {...{ item, value, setFieldValue }} />;
  return <InputField {...{ item, helperText }} />;
};

export const uploadFile = async (file, setProgress) => {
  let bucket = null;
  let key = null;

  if (file.type.split("/")[0] === "video") {
    if (file.size > 5000000000) throw new Error('Your video is larger than maximum size 5Gb');
    bucket = process.env.GATSBY_BUCKET_VIDEO;
  };

  if (file.type.split("/")[0] === "image") {
    if (file.size > 5000000) throw new Error('Your image is larger than maximum size 5Mb');
    bucket = process.env.GATSBY_BUCKET_IMAGE;
  }

  Storage.configure({ bucket: bucket });

  await Auth.currentCredentials()
    .then(async (data) => {
      key = `public/images/${data.identityId}/${RenameFile(file.name)}`;
      await Storage.put(key, file, {
        contentType: file.type,
        customPrefix: { public: "" },
        progressCallback(progress) {
          setProgress((100 * progress.loaded) / progress.total);
        },
      });
    })
    .catch(err => {
      console.log(err);
      throw new Error(err);
    });

  const result = {
    region: process.env.GATSBY_REGION,
    bucket: bucket,
    key: key
  }

  return result;
}