import React from 'react';
import { Field, useField } from 'formik';
import { MenuItem, TextField } from '@material-ui/core';

export const SelectField = ({ item }) => {
  const [field, meta] = useField(item.key);
  const errorText = meta.error && meta.touched ? meta.error : '';
  return (
    <Field
      {...field}
      select
      name={item.key}
      label={item.label}
      variant='outlined'
      size='small'
      fullWidth
      helperText={errorText || item.helperText}
      error={!!errorText}
      as={TextField}
    >
      {item.options.map((option) => (
        <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
      ))}
    </Field>
  )
}